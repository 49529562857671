.stick_card{
    padding: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 150px;
    text-overflow: clip;
    margin-bottom: 5px;
}

.stick_image{
    width: 150px;
    height: 150px;
}

.stick_name{
    text-align: center;
}

.caption{
    color: var(--text_secondary);
    text-align: center;
}

.card_grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.stick_card_view{
    width: 160px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}